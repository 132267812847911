import { useState, forwardRef, Fragment } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import OnboardingDetail from "../components/OnboardingDetail";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
// import EditLocationAltIcon from "@mui/icons-material/EditLocationAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import RenderOnRole from "../components/RenderOnRole";

export default function StartedOnboardingsRds() {
  const columns = [
    { field: "id", headerName: "ID", width: 1, hide: true },

    {
      field: "companyId",
      headerName: "Company Id",
      width: 100,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.companyId}
          </Typography>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: (params) => {
        return (
          <Fragment>
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.email}
            </Typography>
          </Fragment>
        );
      },
    },
    {
      field: "step",
      headerName: "Step",
      width: 70,
      renderCell: (params) => {
        return (
          <Fragment>
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.step}
            </Typography>
          </Fragment>
        );
      },
    },
    {
      field: "cardType",
      headerName: "Card Type",
      width: 120,
      renderCell: (params) => {
        return (
          <Fragment>
            <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
              {params.row.cardType}
            </Typography>
          </Fragment>
        );
      },
    },
    {
      field: "action",
      width: 200,
      headerName: "Action",
      renderCell: (params) => {
        return (
          // you will find row info in params
          <div
            style={{
              backgroundColor:
                params.row.status === "REVIEW_IN_PROGRESS"
                  ? "antiquewhite"
                  : "white",
            }}
          >
            <Tooltip title={`Resend the email to ${params.row.email}`}>
              <Button
                style={{ minWidth: "10px" }}
                disabled={resendEmailOnboardingMutation.isLoading}
              >
                <ForwardToInboxIcon
                  onClick={(e) => onResendEmailButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            </Tooltip>

            {params.row.status ? (
              <Tooltip title="Click to edit">
                <Button style={{ minWidth: "10px" }}>
                  <ModeEditIcon
                    style={{ color: "darkgoldenrod" }}
                    onClick={(e) => onEditButtonClick(e, params.row)}
                    variant="contained"
                  />
                </Button>
              </Tooltip>
            ) : (
              <></>
            )}

            {params.row.status ? (
              <Button style={{ minWidth: "10px" }}>
                <ThumbUpAltIcon
                  style={{ color: "green" }}
                  onClick={(e) => onConfirmButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            ) : (
              <></>
            )}
            <Button style={{ minWidth: "10px" }}>
              <DeleteIcon
                style={{ color: "red" }}
                onClick={(e) => onDiscardButtonClick(e, params.row)}
                variant="contained"
              />
            </Button>
            <RenderOnRole roles={["onboarding-super-admin"]}>
              <Button style={{ minWidth: "10px" }}>
                <RemoveCircleOutlineIcon
                  style={{ color: "red" }}
                  onClick={(e) => onRemoveButtonClick(e, params.row)}
                  variant="contained"
                />
              </Button>
            </RenderOnRole>
          </div>
        );
      },
    },
    { field: "duration", headerName: "Duration", width: 80 },

    {
      field: "creationDate",
      headerName: "Creation Date",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.creationDate}
          </Typography>
        );
      },
    },
    {
      field: "updateDate",
      headerName: "Update Date",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.updateDate}
          </Typography>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.status ? params.row.status : "ON GOING"}
          </Typography>
        );
      },
    },
    {
      field: "reason",
      headerName: "Reason",
      width: 450,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: 12, mb: 0 }} gutterBottom>
            {params.row.reason}
          </Typography>
        );
      },
    },
  ];

  const queryClient = useQueryClient();

  const [customerIdentifier, setCustomerIdentifier] = useState();
  const [openEditDlg, setOpenEditDlg] = useState(false);

  const onEditButtonClick = (e, params) => {
    setCustomerIdentifier(params.email);
    setOpenEditDlg(true);
  };

  const handleSuccess = () => {
    refetch();
    setFeedback({
      open: true,
      type: "success",
      message: "The request was sent",
      onClose: () => {
        //clearFunction();
        setFeedback({ open: false });
        handleClose();
      },
    });
  };

  const discardOnboardingMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/discard`,
        { email: request.email }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onDiscardButtonClick = (e, params) => {
    discardOnboardingMutation.mutate({ email: params.email });
  };

  const removeOnboardingMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/remove`,
        { email: request.email }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onRemoveButtonClick = (e, params) => {
    removeOnboardingMutation.mutate({ email: params.email });
  };

  const confirmOnboardingMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/confirm`,
        { email: request.email, type: "rds" }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        debugger;
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err?.response?.data?.error
          ? err?.response?.data?.error
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onConfirmButtonClick = (e, params) => {
    confirmOnboardingMutation.mutate({ email: params.email, type: "rds" });
  };

  const [feedback, setFeedback] = useState({
    open: false,
    type: "error",
    message: "",
  });

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
  });

  const handleFeedbackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setFeedback({ open: false, type: "error", message: "" });
  };

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") return; //avoid closing the modal by clicking the backdrop
    //props.setOpen(false);
  };

  const resendEmailOnboardingMutation = useMutation(
    (request) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/onboarding/resend`,
        { email: request.email }
      );
    },
    {
      onSuccess: () => {
        handleSuccess();
      },
      onError: (err) => {
        const msg = err?.response?.data?.description
          ? err.response.data.description
          : err.message;
        setFeedback({
          open: true,
          type: "error",
          message: msg,
        });
      },
    }
  );

  const onResendEmailButtonClick = (e, params) => {
    resendEmailOnboardingMutation.mutate({ email: params.email });
  };

  async function fetchOnboardingsStarted() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/onboarding/started?type=rds`
    );
    return response;
  }

  const { data, refetch, isLoading, isError } = useQuery(
    ["onboardings-started"],
    fetchOnboardingsStarted
  );

  return (
    <Container maxWidth="xlg">
      <br />
      <br />
      <Grid container spacing={1.5}>
        <Grid item xs={12} md={12}>
          <h1>OnBoarding Applications</h1>

          {isLoading ? (
            <h4>Loading...</h4>
          ) : !isError ? (
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                disableSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                rows={data?.data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                /* sx={{
                      "& .MuiButton-root": {
                        display: "none",
                      }
                    }}*/
              />
            </div>
          ) : (
            <h4>An error ocurred, please try again later...</h4>
          )}
        </Grid>
      </Grid>
      {openEditDlg ? (
        <OnboardingDetail
          action="SAVE"
          open={openEditDlg}
          setOpen={setOpenEditDlg}
          dialogTitle="Edit Onboarding"
          customerIdentifier={customerIdentifier}
          rds={true}
        />
      ) : (
        ""
      )}
      <Snackbar
        open={feedback.open}
        autoHideDuration={6000}
        onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={feedback.onClose ? feedback.onClose : handleFeedbackClose}
          severity={feedback.type}
          sx={{ width: "100%" }}
        >
          {feedback.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}
