import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import HttpService from "../services/HttpService";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UserService from "../services/UserService";


export default function ManageCard(props) {
  const queryClient = useQueryClient();
  const [action, setAction] = React.useState();
  const [showDeactive, setShowDeactive] = React.useState(false);
  const [isLocationEditing, setIsLocationEditing] = React.useState(false);
  const [isMonthlyLimitEditing, setIsMonthlyLimitEditing] = React.useState(false);
  const [newMonthlyPaymentLimit, setNewMonthlyPaymentLimit] = React.useState();
  const [selectedLocation, setSelectedLocation] = React.useState(0);

  async function fetchProcesorData() {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL +
        `/member/card-info?memberId=${props.memberInfo.memberId}&companyId=${props.memberInfo.companyId}`
    );
    return response;
  }

  const { data, isLoading, isFetching } = useQuery(
    ["member-card-info", props.memberInfo.idCustomer],
    () => fetchProcesorData()
  );

  useEffect(() => {
    const statusDisplay = data?.data?.statusDisplay?.toLowerCase();
  
    if (statusDisplay) {
      switch (statusDisplay) {
        case "active":
          setAction("Lock");
          setShowDeactive(true);
          break;
        case "suspended":
          setAction("Unlock");
          setShowDeactive(true);
          break;
        case "card issued":
          setAction();
          setShowDeactive(true);
          break;
        default:
          setAction();
          setShowDeactive(false);
          break;
      }
    } else {
      setAction();
      setShowDeactive(false);
    }
  }, [data?.data?.statusDisplay]);

  const adminCardMutation = useMutation(
    (body) => {
      return HttpService.getAxiosClient().post(
        window.API_URL + `/member/admin-card`,
        body
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "member-card-info",
          props.memberInfo.idCustomer,
        ]);
        setIsMonthlyLimitEditing(false);
        setIsLocationEditing(false);
      },
      onError: (err) => {
        console.error(err);
      },
    }
  );

  const adminCard = (memberId, companyId, _action) => {
    if (!_action) return console.error("Action not defined");
    const body = {
      memberId: memberId,
      companyId: companyId,
      status: _action,
    };
    adminCardMutation.mutateAsync(body);
  };

  const handleChangeMonthlyPaymentLimit = (event) => {
    const newValue = event.target.value;
    if (newValue === '' || (newValue >= 0 && newValue <= 99999)) {
      setNewMonthlyPaymentLimit(newValue);
    }
  };

  const saveMonthlyLimit = () => {
    const body = {
      memberId: props.memberInfo.memberId,
      companyId: props.memberInfo.companyId,
      monthlyPaymentLimit: newMonthlyPaymentLimit,
    };
    adminCardMutation.mutateAsync(body);
  }

  const saveNewLocation = () => {
    const body = {
      memberId: props.memberInfo.memberId,
      companyId: props.memberInfo.companyId,
      locationId: selectedLocation,
    };
    adminCardMutation.mutateAsync(body);
  }

  async function fetchLocations() {
    if (UserService.hasRole(['pc-query-locations'])){
      const response = await HttpService.getAxiosClient().get(
        window.API_URL + `/locations`
      );
      return response;
    }
    return [];
  }

  const {     
    data: dataLocations
  } = useQuery(["locations"], fetchLocations);

  const handleLocationChange = (event,location) => {
    setSelectedLocation(location.props.value);
}

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle
        sx={{ bgcolor: "primary.main", color: "white", fontSize: "1.25rem" }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          Manager Card
          <IconButton
            edge="end"
            color="inherit"
            size="small"
            onClick={() => props.setOpen(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={0} pt={1}>
          <Grid item xs={4}>
            <Typography variant="body" fontWeight={"bold"}>
              Member ID:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body">
              {props?.memberInfo?.memberId}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" fontWeight={"bold"}>
              CardHolder Name:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body">{props?.memberInfo?.name}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body" fontWeight={"bold"}>
              Email:
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="body">{props?.memberInfo?.email}</Typography>
          </Grid>
          <Grid item xs={4} pt={2}>
            <Typography variant="body" fontWeight={"bold"}>
              Card status:
            </Typography>
          </Grid>
          <Grid item xs={8} pt={2} alignItems="center" style={{ display: 'flex' }}>
            <Typography variant="body" pr={2}>
              {isLoading ? "Loading status..." : data?.data?.statusDisplay}
            </Typography>
            <Box>
              {action && (
                <Button
                  disabled={isFetching || adminCardMutation.isLoading}
                  variant="contained"
                  color="primary"
                  size="small"
                  startIcon={action==="Lock" ? <LockIcon /> : <LockOpenIcon />}
                  onClick={() =>
                    adminCard(
                      props.memberInfo.memberId,
                      props.memberInfo.companyId,
                      action.toUpperCase()
                    )
                  }
                >
                  {adminCardMutation.isLoading && (!isMonthlyLimitEditing && !isLocationEditing) ? "Saving..." : action}
                </Button>
              )}
            </Box>
            {showDeactive && (
              <Box ml={1}>
                  <Button
                    disabled={isFetching || adminCardMutation.isLoading}
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<CreditCardOffIcon />}
                    onClick={() =>
                      adminCard(
                        props.memberInfo.memberId,
                        props.memberInfo.companyId,
                        "CLOSE"
                      )
                    }
                  >
                    {adminCardMutation.isLoading && (!isMonthlyLimitEditing && !isLocationEditing) ? "Saving..." : "DEACTIVE"}
                  </Button>
              </Box>
            )}
          </Grid>
          <Grid item xs={4} pt={2}>
              <Typography variant="body" fontWeight={"bold"}>
                Available Balance:
              </Typography>
            </Grid>
            <Grid item xs={8} pt={2} alignItems="center" style={{ display: 'flex' }}>
              <Typography variant="body" pr={2}>
                {isLoading ? "Loading status..." : data?.data?.availableBalance}
              </Typography>
            </Grid>
          <Grid item xs={4} pt={0} alignItems="center" style={{ display: 'flex' }}>
            <Typography variant="body" fontWeight={"bold"}>
              Location:
            </Typography>
          </Grid>
          <Grid item xs={8} pt={0} alignItems="center" style={{ display: 'flex' }}>
            {!!!isLocationEditing ? 
              <>
                <Typography variant="body" pr={2}>
                  {isLoading ? "Loading..." : data?.data?.location_description}
                </Typography>
                <IconButton variant="outlined" onClick={() => {setIsLocationEditing(true)}}>
                  <EditIcon />
                </IconButton>
              </>:
              <>
                <FormControl style={{width:"280px"}}>
                  <InputLabel id="simple-select-label">Location</InputLabel>
                  <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  label="Location"
                  onChange={handleLocationChange}
                  value={selectedLocation}
                  >   
                  <MenuItem value={0}>-</MenuItem>
                  {
                    dataLocations?.data.map((item)=> (<MenuItem key={item.id} value={item.id}>{item.description}</MenuItem>))
                  }
                  </Select>
                </FormControl>
                <IconButton variant="outlined" onClick={() => {saveNewLocation()}} disabled={adminCardMutation.isLoading} >
                  <SaveIcon />
                </IconButton>
                <IconButton variant="outlined" onClick={() => {setIsLocationEditing(false)}}>
                  <CloseIcon />
                </IconButton>
              </>
            }
          </Grid>
          <Grid item xs={4} pt={0} alignItems="center" style={{ display: 'flex' }}>
            <Typography variant="body" fontWeight={"bold"}>
              Monthly Payment Limit:
            </Typography>
          </Grid>
          <Grid item xs={8} pt={0} alignItems="center" style={{ display: 'flex' }}>
            {!!!isMonthlyLimitEditing ? 
              <>
                <Typography variant="body" pr={2}>
                  {isLoading || adminCardMutation.isFetching  ? "Loading..." : data?.data?.monthlyPaymentLimit}
                </Typography>
                <IconButton variant="outlined" onClick={() => {setIsMonthlyLimitEditing(true)}}>
                  <EditIcon />
                </IconButton>
              </>
              :
              <>
                <Input
                  type="number"
                  value={newMonthlyPaymentLimit}
                  onChange={handleChangeMonthlyPaymentLimit}
                  inputProps={{
                    min: 0,
                    max: 99999,
                    style: { width: '150px' }
                  }}
                  defaultValue={data?.data?.monthlyPaymentLimit}
                />
                <IconButton variant="outlined" onClick={() => {saveMonthlyLimit()}} disabled={adminCardMutation.isLoading} >
                  <SaveIcon />
                </IconButton>
                <IconButton variant="outlined" onClick={() => {setIsMonthlyLimitEditing(false)}}>
                  <CloseIcon />
                </IconButton>
              </>
            }
          </Grid>


        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
