import React from "react";
import backgroundImg from "../images/Orange-to-green-background.png";
import Labels from "../labels/Labels";

import UserService from "../services/UserService";
import { AppBar, Box, Button, Grid, Toolbar, Typography } from "@mui/material";
import Footer from "../components/Footer";

export default function Welcome() {
  const [RellevateLogo, setSmallLogo] = React.useState(null);
  const [smileImg, setLogo] = React.useState(null);

  async function getInitials(subdomain) {
    try {
      const extension = subdomain ? `.${subdomain}` : "";
      const [smallLogo, logo] = await Promise.all([
        import(`../company-logo${extension}.svg`),
        import(
          `../images/Man-smiling-transparent-background-e1687753105269${extension}.webp`
        ),
      ]);
      setSmallLogo(smallLogo.default);
      setLogo(logo.default);
    } catch (error) {
      console.error(error);
      getInitials("");
    }
  }

  React.useEffect(() => {
    getInitials(window.subdomain);
  }, []);

  return (
    <Box>
      <AppBar
        position="sticky"
        sx={{ bgcolor: "white", boxShadow: "0 10px 8px 0 rgba(0,0,0,.15)" }}
      >
        <Toolbar>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <img
              src={RellevateLogo}
              alt="upload"
              width="100%"
              loading="lazy"
              style={{
                marginLeft: 0,
                marginTop: 20,
                marginBottom: 20,
                maxWidth: "260px",
              }}
            />
          </Box>
          <Button
            color="primary"
            variant="contained"
            sx={{ fontSize: "15px" }}
            onClick={() => UserService.doLogin()}
          >
            SIGN IN
          </Button>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          padding: 0,
          margin: 0,
          backgroundImage: `url(${backgroundImg})`,
          minHeight: "90vh",
          width: "100%",
          backgroundColor: "#100F9200",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: "80%" }}>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <Typography variant="h3" sx={{ fontWeight: "600" }} pt={20}>
                {Labels.getLabel("welcomePage.Title", "Employer Portal")}
              </Typography>
              <Typography variant="body1" pt={1} fontSize={20}>
                {Labels.getLabel(
                  "welcomePage.Subtitle",
                  "At the forefront of Accessing, Using, and Moving money anytime, anywhere."
                )}
              </Typography>
              <Box ml={3} mt={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      fontSize={20}
                    >
                      Digital Funds Management
                    </Typography>
                    <Typography variant="body2" fontSize={16}>
                      <li>Balance Control</li>
                      <li>Instant Payments</li>
                      <li>Team grouping</li>
                      <li>Custom payment labeling</li>
                      <li>Payment alerts</li>
                      <li>Analytics</li>
                      <li>Real time insights</li>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: "bold" }}
                      fontSize={20}
                    >
                      Card Management
                    </Typography>
                    <Typography variant="body2" fontSize={16}>
                      <li>Card Ordering</li>
                      <li>Multiple Locations</li>
                      <li>Standard or Personalized Embossing</li>
                      <li>Card Handout/Distribution</li>
                      <li>Adaptive Personal Info Collection</li>
                      <li>Analytics</li>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ fontSize: "15px" }}
                  onClick={() => UserService.doLogin()}
                  pt={2}
                >
                  SIGN IN
                </Button>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <img
                src={smileImg}
                alt="upload"
                width="100%"
                loading="lazy"
                style={{
                  marginLeft: 0,
                  marginTop: 20,
                  marginBottom: 20,
                  maxWidth: "500px",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
