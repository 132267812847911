import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { BarChart, LineChart } from "@mui/x-charts";

export default function CardChart({
  title,
  dataset,
  valueFormatter,
  chartSetting,
  xAxisDataKey,
  seriesDataKey,
  area = false,
  showMark = true,
  chartType = "BarChart",
  isFecthing = false,
  isError = false,
}) {

  if (isFecthing || dataset === undefined) {
    return (
      <Card>
        <Grid container padding={0.5}>
          <Grid item xs={12}>
            <Typography variant="body">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress color="secondary" />
          </Grid>
        </Grid>
      </Card>
    );
  }

  if (isError || dataset?.length === 0) {
    return (
      <Card>
        <Grid container padding={0.5}>
          <Grid item xs={12}>
            <Typography variant="body">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="caption">No data available at the moment. Please check back later!</Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }

  return (
    <Card>
      <Grid container padding={0.5}>
        <Grid item xs={12}>
          <Typography variant="body">{title}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            {chartType === "LineChart" ? (
              <LineChart
                dataset={dataset}
                xAxis={[{ scaleType: "band", dataKey: xAxisDataKey }]}
                series={[
                  {
                    dataKey: seriesDataKey,
                    area: area,
                    showMark: showMark,
                    valueFormatter,
                  },
                ]}
                {...chartSetting}
              />
            ) : (
              <BarChart
                dataset={dataset}
                xAxis={[{ scaleType: "band", dataKey: xAxisDataKey }]}
                series={[{ dataKey: seriesDataKey, valueFormatter }]}
                {...chartSetting}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
}
