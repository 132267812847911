import HttpService from "../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import {useState} from  'react';
import Members from "../components/Members";
import { Box } from "@mui/material";

export default function QueryMembers() {

  const [page, setPage] = useState(0);
  const [filterString, setFilterString] = useState("");
  const [underReviewOnly, setUnderReviewOnly] = useState(false);


  async function fetchMembers(page, filterString, underReviewOnly) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/members?pageSize=10&page=`+page +`&filter=`+filterString+`&underReviewOnly=`+underReviewOnly
    );
    return response;
  }

  async function fetchMembersCount(filterString, underReviewOnly) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/pad/members/count?filter=`+filterString+`&underReviewOnly=`+underReviewOnly
    );
    return response;
  }



  const { 
    data, 
    isFetching, 
    isLoading, 
    isError, 
    refetch 
  } = useQuery(["members",page, filterString, underReviewOnly], () => fetchMembers(page, filterString, underReviewOnly), {
    keepPreviousData: true
  });

  const { 
    data : dataCount, 
    isFetching : isFetchingDataCount, 
    isLoading : isLoadingDataCount, 
    isError: isErrorDataCount, 
    refetch: refetchDataCount 
  } = useQuery(["membersCount", filterString, underReviewOnly], () => fetchMembersCount(filterString, underReviewOnly), {
    keepPreviousData: true
  });



  return (
    <Box sx={{ margin: '0'}}>
      <Members 
        isFetching={isFetching} 
        isLoading={isLoading} 
        isError={isError} 
        data={data} 
        refetchFunction={refetch} 
        setPageFunction={setPage}
        setFilterStringFunction={setFilterString}
        setUnderReviewOnlyFunction={setUnderReviewOnly}
        dataCount={dataCount} 
        isFetchingDataCount={isFetchingDataCount}
        isLoadingDataCount={isLoadingDataCount}
        isErrorDataCount={isErrorDataCount}
        refetchDataCount={refetchDataCount} 
        title="Members/Workers"

      />
    </Box>
  );
}
