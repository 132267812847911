import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Container,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Card,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useState } from "react";
import CardChart from "../../components/CardChart";
import HttpService from "../../services/HttpService";
import { useQuery } from "@tanstack/react-query";
import QueryBalance from "../QueryBalance";

export function valueFormatter(value) {
  return `${value}`;
}

const generateChartSetting = (numberOfRows) => {
  return {
    width: 1200 / numberOfRows,
    height: 300,
  };
};

export default function CardsDashboard() {
  const [dateRangeCards, setDateRangeCards] = useState("year");
  const [dateRangeDisbursed, setDateRangeDisbursed] = useState("year");
  const [dateRangeBalance, setDateRangeBalance] = useState("year");

  async function fecthXYChartData(idReport, dateRangeCards) {
    const response = await HttpService.getAxiosClient().get(
      window.API_URL + `/report/charts/xychart/${idReport}/${dateRangeCards}`
    );
    return response;
  }

  const {
    data: cardsDistributedData,
    isFetching: cardsDistributedIsFetching,
    isError: cardsDistributedIsError,
  } = useQuery(
    ["cardsDistributed", dateRangeCards],
    () => fecthXYChartData("cardsDistributed", dateRangeCards),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: cardsActivatedData,
    isFetching: cardsActivatedIsFetching,
    isError: cardsActivatedIsError,
  } = useQuery(["cardsActivated", dateRangeCards],
    () => fecthXYChartData("cardsActivated", dateRangeCards), {
    keepPreviousData: true,
  });

  const {
    data: cardsUnactivatedData,
    isFetching: cardsUnactivatedIsFetching,
    isError: cardsUnactivatedIsError,
  } = useQuery(
    ["cardsUnactivated", dateRangeCards],
    () => fecthXYChartData("cardsUnactivated", dateRangeCards),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: balanceDisbursedData,
    isFetching: balanceDisbursedIsFetching,
    isError: balanceDisbursedIsError,
  } = useQuery(
    ["balanceDisbursed", dateRangeDisbursed],
    () => fecthXYChartData("balanceDisbursed", dateRangeDisbursed),
    {
      keepPreviousData: true,
    }
  );

  const {
    data: cardsDisbursedData,
    isFetching: cardsDisbursedIsFetching,
    isError: cardsDisbursedIsError,
  } = useQuery(["cardsDisbursed", dateRangeDisbursed],
     () => fecthXYChartData("cardsDisbursed", dateRangeDisbursed), {
    keepPreviousData: true,
  });

  const {
    data: maxBalanceData,
    isFetching: maxBalanceIsFetching,
    isError: maxBalanceIsError,
  } = useQuery(["maxBalance", dateRangeBalance],
    () => fecthXYChartData("maxBalance", dateRangeBalance), {
    keepPreviousData: true,
  });

  const {
    data: avgBalanceData,
    isFetching: avgBalanceIsFetching,
    isError: avgBalanceIsError,
  } = useQuery(["avgBalance", dateRangeBalance],
    () => fecthXYChartData("avgBalance", dateRangeBalance), {
    keepPreviousData: true,
  });

  const {
    data: minBalanceData,
    isFetching: minBalanceIsFetching,
    isError: minBalanceIsError,
  } = useQuery(["minBalance", dateRangeBalance],
    () => fecthXYChartData("minBalance", dateRangeBalance), {  
    keepPreviousData: true,
  });

  return (
    <Container maxWidth="xlg">
      <Accordion
        defaultExpanded={true}
        sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          Cards Dashboard
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: "30%", padding: 8 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date Range:
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value = {dateRangeCards}
                    label="Date Range"
                    onChange={(value) => setDateRangeCards(value.target.value)}
                  >
                    <MenuItem value={"year"}>This Year</MenuItem>
                    <MenuItem value={"lastyear"}>Last Year</MenuItem>
                    <MenuItem value={"month"}>This month</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <CardChart
                title="Cards Distributed"
                dataset={cardsDistributedData?.data}
                isLoading={cardsDistributedIsFetching}
                isError={cardsDistributedIsError}
                valueFormatter={valueFormatter}
                chartSetting={generateChartSetting(3)}
                xAxisDataKey="xValue"
                seriesDataKey="yValue"
              />
            </Grid>
            <Grid item xs={4}>
              <CardChart
                title="Cards Activated"
                dataset={cardsActivatedData?.data}
                isLoading={cardsActivatedIsFetching}
                isError={cardsActivatedIsError}
                valueFormatter={valueFormatter}
                chartSetting={generateChartSetting(3)}
                xAxisDataKey="xValue"
                seriesDataKey="yValue"
              />
            </Grid>
            <Grid item xs={4}>
              <CardChart
                title="Cards Unactivated"
                dataset={cardsUnactivatedData?.data}
                isLoading={cardsUnactivatedIsFetching}
                isError={cardsUnactivatedIsError}
                valueFormatter={valueFormatter}
                chartSetting={generateChartSetting(3)}
                xAxisDataKey="xValue"
                seriesDataKey="yValue"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Disbursed funds
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Card style={{ width: "30%", padding: 8 }}>
                <FormControl size="small" fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Date Range:
                  </InputLabel>
                  <Select
                    value = {dateRangeDisbursed}
                    label="Date Range"
                    onChange={(value) => setDateRangeDisbursed(value.target.value)}
                  >
                    <MenuItem value={"year"}>This Year</MenuItem>
                    <MenuItem value={"lastyear"}>Last Year</MenuItem>
                    <MenuItem value={"month"}>This month</MenuItem>
                    <MenuItem value={"today"}>Today</MenuItem>
                  </Select>
                </FormControl>
              </Card>
            </Grid>
            <Grid item xs={6}>
              <CardChart
                title="Balance Disbursed"
                dataset={balanceDisbursedData?.data}
                isLoading={balanceDisbursedIsFetching}
                isError={balanceDisbursedIsError}
                valueFormatter={valueFormatter}
                chartSetting={generateChartSetting(2)}
                xAxisDataKey="xValue"
                seriesDataKey="yValue"
              />
            </Grid>
            <Grid item xs={6}>
              <CardChart
                title="Cards Disbursed"
                dataset={cardsDisbursedData?.data}
                isLoading={cardsDisbursedIsFetching}
                isError={cardsDisbursedIsError}
                valueFormatter={valueFormatter}
                chartSetting={generateChartSetting(2)}
                xAxisDataKey="xValue"
                seriesDataKey="yValue"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion sx={{ backgroundColor: "rgba(218, 218, 218, 0.25)" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Employer balance
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <QueryBalance showMovements={false} />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  );
}
